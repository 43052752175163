declare interface Window {
    navigation: NavigationPage;
}

interface IPage {

    onload?(): Promise<any> | void;
    onready?(item?: any): Promise<any> | void;
    onunload?(): Promise<any> | void;
}

class TestyClass {
    public url: string;
    public page: IPage;

    constructor(u, p) {
        this.url = u;
        this.page = p;
    }
}


class NavigationPage {

    //public stack: Array<IPage>;

    public stack: Array<TestyClass>;
    private element: HTMLElement;


    constructor(element: HTMLElement) {
        this.element = element;

        this.stack = new Array();


        //if (window.history && window.history.pushState) {

        //    $(window).on('popstate', function (e) {
        //        alert('Back button was pressed.');
        //    });

        //}
        
    }

    public back() {

        this.stack.pop();
        var lastPage = this.stack[this.stack.length - 1];
        this.navigate(lastPage.url, lastPage.page);
            

    }

    public async navigate(url: string, page: IPage) {

        //window.history.pushState('forward', null, './#');

        if (this.stack.length) {
            var currentPage = this.stack[this.stack.length - 1].page;
            if (currentPage.onunload)
                var onunloadresult = await currentPage.onunload();
        }


        this.stack.push(new TestyClass(url, page));

        this.beginNavigate();

        if (page.onload)
            var onloadresult = await page.onload();



        var html = await new Promise<string>((resolve, reject) => {
            var xmlHttp = new XMLHttpRequest();

            xmlHttp.open("GET", url);

            xmlHttp.setRequestHeader("X-Requested-With", "XMLHttpRequest")


            xmlHttp.onreadystatechange = function () {
                if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
                    resolve(xmlHttp.responseText);

                if (xmlHttp.status == 401) {
                    window.location.href = '/';
                }
            }

            
            xmlHttp.send();
        });

        this.element.innerHTML = html;

        if (page.onready)
            var readyresult = await page.onready(onloadresult);
        

        this.endNavigate();
    }

    private beginNavigate(): void {
        this.element.style.visibility = "hidden";
        while (this.element.firstChild) {
            this.element.removeChild(this.element.firstChild);
        }
    }

    private endNavigate(): void {
        this.element.style.visibility = "visible";
    }
}